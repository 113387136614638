<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Analise de Agência Pendente</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 

            <template v-slot:item.phone="{ item }">
              {{ item.phone | phone2 }}
            </template>

            <template v-slot:top>
              <BrToolBar 
                @search="buscar" 
                :configFilter="{ 
                  listaSelect: item, 
                  getItens: getItens,
                  jsonData: true,
                }"
                :labelTextField="'Busca por Código'"
              />
            </template>

            <template v-slot:item.action="{ item }">

              <v-btn
                v-if="permiteAcao({path: '/gestao/entidades/pendentes/aprovar'}, 'add')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Avaliar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Reprovar
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import Events from '@/core/service/events'
import { errorSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'AgenciasPendentes',
  components: {
    BrToolBar: () => import('./components/toolbar'),
  },
  mixins: [listagemTables],
  data () {
    return {
      typeBusca: 'entities',
      loadingTable: false,
      headers: [
        { align: 'start', class: 'table-header', text: 'Id', value: 'id' },
        { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
        { align: 'start', class: 'table-header', text: 'Telefone', value: 'phone' },
        { align: 'start', class: 'table-header', text: 'Data Nascimento', value: 'birth' },
        { align: 'start', class: 'table-header', text: 'Data Criacão', value: 'date' },
        { align: 'start', class: 'table-header', text: 'Data Atualização', value: 'updated' },
        { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
      ]
    }
  },

  async mounted () {
    this.loadingTable = true
    this.getData()
  },

  computed: {
    ...mapGetters('pendingEntities', ['listaItens','totalItens','item']),
  },

  methods: {
    ...mapActions('pendingEntities', ['getItens','getItem','deletarItem']),

    init () {},

    getData () {
      this.loadingTable = true
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  _addlFilter: this.filter ? this.filter : undefined })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },
  }
}
</script>

<style lang="scss">
  @import '../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
